import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from './pages/partials/components/navbar';
import Editions from './pages/phirst-editions/components/index-editions';
import AmenityNodes from './pages/amenity-nodes/components/index-amenity';

import Impressions from './pages/phirst-impressions/components/index-impressions';
import Fairgrounds from './pages/phirst-fairgrounds/components/index-fairgrounds';
import Boroughs from './pages/phirst-boroughs/components/index-boroughs';

import Landing from './pages/landing-page/components/main';

import PrivacyPolicyV2 from './pages/privacy-policy/components/privacyPolicy';
import ContactUs from './pages/contact-us/components/contact-index';
import PhirstServ from "./pages/contact-us/components/contact-phirst-serv";
import AboutUs from './pages/about-us/components/index-about';

import Explore from './pages/explore/components/index-explore';

import Pavilion from './pages/sales-pavilion/components/index-pavilion';

import VicinityMap from './pages/vicinity-map/components/index-map';

import CalistaEnd from './pages/phirst-impressions/components/unit-calista-end';
import CalistaMid from './pages/phirst-impressions/components/unit-calista-mid';
import CalistaPair from './pages/phirst-impressions/components/unit-calista-pair';


import UnitCartland from './pages/phirst-editions/components/unit-cartland';
import UnitCharles70 from './pages/phirst-editions/components/unit-charles-70';
import UnitCharles150 from './pages/phirst-editions/components/unit-charles-150';
import UnitChristie from './pages/phirst-editions/components/unit-christie';
import UnitCorin from './pages/phirst-editions/components/unit-corin';

import Slides from './pages/sales-pavilion/components/slides';

import CalistaBareUnits360 from './pages/360/calista-bare-units/components';
import CalistaExterior360 from './pages/360/calista-exterior/component';
import CalistaEnd360 from './pages/360/calista-end/component';
import CalistaMid360 from './pages/360/calista-mid/component';
import CalistaPair360 from './pages/360/calista-pair/component';
import SalesPavilion360 from './pages/360/sales-pavilion/components';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Navbar />
      <Routes>
          <Route path='/' element={ <Landing /> } />
          <Route path='phirst-impressions' element={ <Impressions /> } />
          <Route path='phirst-editions' element={ <Editions /> } />
          <Route path='phirst-boroughs' element={ <Boroughs /> } />
          <Route path='amenity-nodes' element={ <AmenityNodes /> } />

          <Route path='phirst-fairgrounds' element={ <Fairgrounds /> } />

          <Route path='privacy-policy' element={ <PrivacyPolicyV2 /> } />
          <Route path='contact-us' element={ <ContactUs /> } />
          <Route path="phirst-serv" element={<PhirstServ />} />
          <Route path='about-us' element={ <AboutUs /> } />

          <Route path='explore' element={ <Explore /> } />

          <Route path='sales-pavilion' element={ <Pavilion /> } />
          
          <Route path='vicinity-map' element={ <VicinityMap /> } />

          <Route path='calista-end' element={ <CalistaEnd /> } />
          <Route path='calista-mid' element={ <CalistaMid /> } />

          <Route path='calista-pair' element={ <CalistaPair /> } />

          <Route path='cartland-54' element={ <UnitCartland />} />
          <Route path='charles-70' element={ <UnitCharles70 />} />
          <Route path='charles-150' element={ <UnitCharles150 />} />
          <Route path='christie-90' element={ <UnitChristie />} />
          <Route path='corin-120' element={ <UnitCorin />} />
          <Route path='slides' element={ <Slides />} />


          <Route path='calista/bare-units/360' element={ <CalistaBareUnits360 />}/>
          <Route path='calista/exterior/360' element={ <CalistaExterior360 />}/>
          <Route path='calista/end/360' element={ <CalistaEnd360 />}/>
          <Route path='calista/mid/360' element={ <CalistaMid360 />}/>
          <Route path='calista/pair/360' element={ <CalistaPair360 />}/>
          <Route path='sales-pavilion/360' element={ <SalesPavilion360 />}/>


      </Routes>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
