import React, { useEffect, useState } from "react";
import "../css/contact-phirst-serv.css";
import LoadScreen from "../../others/components/loadScreen";

function PhirstServ() {

    const [ loadState, setLoadState ] = useState(false)
    
    useEffect(() => {
        setLoadState(true)
    }, [])


    return (
        <>
            { loadState ? <LoadScreen/> : ''}
            <div className="phirst-serv-container">
                <iframe src="https://phirstparkhomes.freshdesk.com/support/home" className="phirst-serv-iframe"></iframe>
            </div>
        </>

    );
  }
  
export default PhirstServ;