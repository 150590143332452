import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import '../css/navbar.css'

const Navbar = () => {

    const centraleLogo = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/centrale-no-hermosa-logo-v2.png'
    const menuIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/menu-icon.png'
    const menuIconAlt = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/menu-icon-alt.png'

    const [navbarMobileShown, setNavbarState] = useState(false)
    const [dropdownItemsShown, setDropdownState] = useState(false)

    // useEffect(() => {
    //     let navLinks = document.getElementById('navLinks')
    //     if (navbarMobileShown) {
    //         navLinks.classList.add('show')
    //     } else {
    //         navLinks.classList.remove('show')
    //     }
    // }, [navbarMobileShown])

    useEffect(() => {
        let navSmall = document.getElementById('navSmall')
        if (navbarMobileShown) {
            navSmall.classList.add('show')
        } else {
            navSmall.classList.remove('show')
        }
    }, [navbarMobileShown])

    // Toggles the visibility of the dropdown links.
    // useEffect(() => {
    //     let dropdownItems = document.getElementById('dropdownItems')
    //     if (dropdownItemsShown) {
    //         dropdownItems.classList.add('show')
    //     } else {
    //         dropdownItems.classList.remove('show')
    //     }
    // }, [dropdownItemsShown])

    const [menu, setMenu] = useState(false)
    useEffect(() => {
        let content = document.querySelector('.content')

        if (content) {
            content.addEventListener('click', () => {
                setMenu(false)
            })
            return () => {
                content.removeEventListener('click', () => {
                    setMenu(false)
                })
            }
        }

    })

    // const toggleMenu = () => {
    //     setMenu((prevState) => !prevState)
    // }

    return (
        <>
            <nav className="nav" id="navbar">
                <NavLink to='/' onClick={() => { setDropdownState(false); setNavbarState((prevState) => !prevState) }}>
                    <img className="centrale-logo" src={centraleLogo} alt="" />
                </NavLink>
                <img className="mobile-menu-btn" src={(navbarMobileShown ? menuIconAlt : menuIcon)} onClick={() => { setNavbarState((prevState) => !prevState) }} alt="" />
                {/* <button className="mobile-menu-btn" onClick={ () => { setNavbarState((prevState) => !prevState)} }></button> */}
                <ul className="nav-links desktop" id="navLinks">

                    {/* Mobile view links */}
                    {/* <div className="dropdown-cont" id="dropdownCont">
                            <button className="dwell-btn" onClick={() => { setDropdownState((prevState) => !prevState) }}>Dwell</button>
                            <div className="dropdown-items" id="dropdownItems">
                                <NavLink to={'/phirst-impressions'} onClick={() => { setDropdownState(false); setNavbarState((prevState) => !prevState) }}>
                                    PHirst Impressions
                                </NavLink>
                                <NavLink to={'/phirst-editions'} onClick={() => { setDropdownState(false); setNavbarState((prevState) => !prevState) }}>
                                    PHirst Editions
                                </NavLink>
                                <NavLink to={'/amenity-nodes'} onClick={() => { setDropdownState(false); setNavbarState((prevState) => !prevState) }}>
                                    Amenity Nodes
                                </NavLink>
                            </div>
                        </div> */}

                    <li className="nav-item dropdown">
                        <a href="#" className="nav-link">
                            Dwell<i className="bi bi-caret-down-fill dropdown-icon"></i>
                        </a>
                        <ul className="dropdown-menu">
                            <li>
                                <NavLink to={'/phirst-impressions'}>
                                    PHirst Impressions
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/phirst-editions'}>
                                    PHirst Editions
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/amenity-nodes'}>
                                    Amenity Nodes
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <NavLink to={'/phirst-fairgrounds'}>
                            Create
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={'/phirst-boroughs'}>
                            Thrive
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={'/about-us'}>
                            About Us
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={'/explore'}>
                            Explore
                        </NavLink>
                    </li>
                    <li className="nav-item dropdown">
                        <a href="#" className="nav-link">
                            Contact Us<i className="bi bi-caret-down-fill dropdown-icon"></i>
                        </a>
                        <ul className="dropdown-menu">
                            <li>
                                <NavLink to="phirst-serv">PHirst Serv&nbsp;&nbsp;&nbsp;</NavLink>
                            </li>
                            <li>
                                <NavLink to="contact-us">Get in Touch</NavLink>
                            </li>
                        </ul>
                    </li>
                </ul>

                <div className="nav-links small-screen" id="navSmall">
                    <div>
                        <NavLink to="" className={({ isActive }) => (isActive ? "active" : "")} onClick={() => { setMenu(false) }}>
                            Home
                        </NavLink>
                        <NavLink to={'/phirst-impressions'}>
                            PHirst Impressions
                        </NavLink>
                        <NavLink to={'/phirst-editions'}>
                            PHirst Editions
                        </NavLink>
                        <NavLink to={'/amenity-nodes'}>
                            Amenity Nodes
                        </NavLink>
                        {/* <NavLink to="amani-series" onClick={ () => { setMenu(false) }}>
                            Amani
                        </NavLink>
                        <NavLink to="alora-series" onClick={ () => { setMenu(false) }}>
                            Alora
                        </NavLink> */}
                        <NavLink to="about">
                            About Us
                        </NavLink>
                        <NavLink to="explore-sights">
                            Explore
                        </NavLink>
                        <NavLink to="contact-us">
                            Get in Touch
                        </NavLink>
                        <NavLink to="phirst-serv">
                            PHirst Serv
                        </NavLink>
                        <NavLink to="privacy-policy">
                            Privacy Policy
                        </NavLink>
                    </div>
                </div>

            </nav>
        </>
    )
}

export default Navbar